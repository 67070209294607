@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.main-container {
  display: flex;
  height: 100vh;
  background-color: #ffffff;
}

.inter-font {
  font-family: "Inter", sans-serif;
}

.left-sidepanel {
  width: 299px;
  background-color: #f0f3ff;
  padding: 20px 10px 20px 20px;
  /* box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); */
  height: 100vh; 
  display: flex;
  flex-direction: column; 
}

.left-sidepanel .custom-scrollbar{
  height: 100%;
  overflow: auto;
}

.left-sidepanel .space-y-4 {
  flex: 1;
}

.left-sidepanel .space-y-2 {
  margin-bottom: 20px;
}

.left-sidepanel h2 {
  margin-bottom: 10px;
}

.left-sidepanel h3 {
  margin-bottom: 10px;
}

.left-sidepanel button {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 10px;
  margin-bottom: 2px;
  border-radius: 20px;
  color: white;
  font-weight: normal;
  font-size: 0.75rem;
  border: none;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
}

.btn-general {
  background-color: #6d7daf;
}

.btn-insurance {
  background-color: #101939;
}

.btn-charges {
  background-color: #00a89a;
}

.main-content {
  flex: 1;
  padding: 40px;
  overflow-x: auto; 
}

.payments-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-bar {
  display: flex;
  gap: 20px;
}

.bg-custom-blue {
  background-color: #007bff;
}

.logo {
  width: 160px;
  height: 43.64px;
}


.btn-general.selected, .btn-insurance.selected, .btn-charges.selected {
  background-color: #4A90E2; 
  border: 2px solid #FFFFFF; 
}

.custom-scrollbar {
  /* scrollbar-width: thin; */
  /* scrollbar-color: #C2CFF8 #f2f5ff; */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #F5F8FF;
}

.custom-scrollbar::-webkit-scrollbar-thumb {

  background-color: #C2CFF8;
  border-radius: 10px;
  border: 2px solid #f2f5ff;
}

.not-provided {
  width: 121px;
  height: 25px;
  background: #AFC2FF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  font-size: 14px;
}

.number-value {
  width: 83px;
  height: 25px;
  background: #00A89A;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Arial', sans-serif;
  font-weight: 700;
  font-size: 14px;
}

/* .hover-button {
  transition: color 0.3s ease;
} */

.hover-button:hover {
  color: #111560;
}